import React from "react";
import LayoutLocalized from "../layouts/LayoutLocalized";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { PageContext } from "../store/context/page-context";

import "../assets/scss/fonts/cnFonts.scss";
import "../assets/scss/fonts/jpFonts.scss";
import "../assets/scss/fonts/localization.scss";

import { graphql } from "gatsby";
import FirstStep from "../components/seller-kit/Step/FirstStep";
import SecondStep from "../components/seller-kit/Step/SecondStep";
import ThirdStep from "../components/seller-kit/Step/ThirdStep";
import NeedHelp from "../components/seller-kit/NeedHelp";
import { getTrueClasses } from "../helpers";
import "../components/seller-kit/index.scss";
import "../components/seller-kit/local.scss";

const PgSKR = ({ pageContext }) => {
  const { i18n } = pageContext;
  const { t } = useTranslation();
  return (
    <PageContext.Provider
      value={{
        translate: t,
        pathname: "/seller-kit-reselling",
        isIframe: false,
      }}
    >
      <LayoutLocalized bgHeader="#fff">
        <Seo
          title={t("title")}
          description="Use AMZScout and make Amazon product research easy with accurate research tools and seller software: AMZScout PRO Chrome Extension, Product Database, Keyword Search and more. Analyze niches and find the right product to make money. Start your free trial."
          page={i18n.path}
          manifest="browserconfig.xml"
          ogImg={OgImg}
        />
        <section
          className={getTrueClasses(
            "PgSK",
            t("code") === "zh" && "lang_zh PgSK_zh",
            t("code") === "ja" && "lang_ja PgSK_ja",
            t("code") === "de" && "PgSK_de"
          )}
        >
          <h1 className="PgSK__mainTitle">
            <span>{`${t("Seller Kit")}`}</span>{" "}
            {`${t("for Success on Amazon")}`}
          </h1>
          <FirstStep isOaVersion />
          <SecondStep isOaVersion />
          <ThirdStep isOaVersion />
          <NeedHelp />
        </section>
      </LayoutLocalized>
    </PageContext.Provider>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "seller-kit" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default PgSKR;
